<template>
    
    <div class="flex flex-col transition-all duration-300" :class="{
        'opacity-0': !show,
        'overflow-hidden' : overflowMode,
    }" :style="`height: ${show ? transitionChildHeight : 0}px;`">
        <div ref="container" class="flex flex-col">
            <slot />
        </div>
    </div>

</template>

<script setup lang="ts">
import { onMounted, ref, useSlots, computed, cloneVNode, Ref, watch, onUpdated, onUnmounted } from "vue";

const slots = useSlots();
const container: Ref<HTMLDivElement | undefined> = ref();
const transitionChildHeight = ref();
const overflowMode = ref(true);

const props = defineProps({
    show: {
        type: Boolean,
        default: true,
    }
});

let timeoutChangeOverflow: any;

watch(() => props.show, () => {
    OnShowChange();
});

onUpdated(() => {
    setTimeout(() => {
        ComputeDimensions();
    })
})

function OnShowChange() {
    ComputeDimensions();
    clearTimeout(timeoutChangeOverflow);
    if (props.show) {
        timeoutChangeOverflow = setTimeout(() => {
            overflowMode.value = false;
        }, 500)
    } else {
        overflowMode.value = true;
    }
}

function ComputeDimensions() {
    const dimensions = container.value?.getBoundingClientRect();
    transitionChildHeight.value = `${dimensions?.height}`;
}

let observer: MutationObserver;

onMounted(() => {
    OnShowChange();
    observer = new MutationObserver(ComputeDimensions);
    observer.observe(container.value, { subtree: true, attributes: true, characterData: true, childList: true });
})

onUnmounted(() => {
    observer.disconnect();
})

</script>